import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap, of, catchError, throwError, finalize, BehaviorSubject, shareReplay, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { CartService } from '../core/services/cart.service';
import { Router } from '@angular/router';

interface AuthResponse {
  success: boolean;
  message: string;
  data: { token: string; id: number; activeRole: string; refreshToken: string };
}

interface FranchisorData {
  role: string;
  name: string;
  email: string;
  phoneNumber: string;
  password: string;
  entity: string;
  brandName: string;
  founded: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = environment.apiUrl;
  private userRole: string | null = null;
  private tokenKey = 'token';

  // Add the refreshTokenFailed property
  public refreshTokenFailed: boolean = false; 

  private isAuthenticatedSubject = new BehaviorSubject<boolean>(!!localStorage.getItem('token'));
  isAuthenticated$ = this.isAuthenticatedSubject.asObservable().pipe(shareReplay(1));
  getRoleSubject = new BehaviorSubject<any>(localStorage.getItem('role'));
  theRole$ = this.getRoleSubject.asObservable().pipe(shareReplay(1));

  constructor(
    private http: HttpClient,
    private cart: CartService,
    private router: Router
  ) {
    this.loadUserFromLocalStorage();
  }

  private loadUserFromLocalStorage() {
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('role');
    
    if (token && role) {
      this.isAuthenticatedSubject.next(true);
      this.getRoleSubject.next(role);
    } else {
      this.isAuthenticatedSubject.next(false);
      this.getRoleSubject.next('');
    }
  }

  login(phoneNumber: string, password: string, role?: string): Observable<any> {
    const endpoint = role === 'Management'? '/login/management' : '/login';
    return this.http.post<any>(`${this.apiUrl}/auth${endpoint}`, { phoneNumber, password })
     .pipe(
      switchMap((response: AuthResponse) => {
        localStorage.setItem('role', response.data.activeRole);
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('refreshToken', response.data.refreshToken);
        this.isAuthenticatedSubject.next(true);
        this.getRoleSubject.next(response.data.activeRole);
        this.cart.getCart().subscribe();
        return of(response);
      }),
      catchError((error: HttpErrorResponse) => {
        console.error('Login failed:', error);
        this.isAuthenticatedSubject.next(false);
        this.getRoleSubject.next('');
        return throwError(() => error);
      }),
      finalize(() => {
      })
    );
  }

  loginManagement(data: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/auth/login/management`, data)
    .pipe(
      switchMap((response: AuthResponse) => {
        localStorage.setItem('role', response.data.activeRole);
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('refreshToken', response.data.refreshToken);
        this.isAuthenticatedSubject.next(true);
        this.getRoleSubject.next(response.data.activeRole);
        return of(response);
      }),
      catchError((error: HttpErrorResponse) => {
        console.error('Login failed:', error);
        this.isAuthenticatedSubject.next(false);
        this.getRoleSubject.next('');
        return throwError(() => error);
      }),
      finalize(() => {
      })
    );
  }

  refreshToken(): Observable<AuthResponse> {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
      return throwError(() => new Error('No refresh token available'));
    }

    const headers = new HttpHeaders({
      'Ref-Authorization': `${refreshToken}`
    });
    
    return this.http.post<AuthResponse>(`${this.apiUrl}/auth/request`, {}, { headers }).pipe(
      switchMap((response: AuthResponse) => {
        // Reset the refreshTokenFailed flag on successful refresh
        this.refreshTokenFailed = false; 
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('refreshToken', response.data.refreshToken);
        return of(response);
      }),
      catchError((error: HttpErrorResponse) => {
        console.error('Token refresh failed:', error);
        this.refreshTokenFailed = true; // Set the flag on failure
        this.logout();
        return throwError(() => error);
      })
    );
  }

  logout() {
    localStorage.clear();
    this.isAuthenticatedSubject.next(false);
    this.getRoleSubject.next('');
  }

  isAuthenticated(): boolean {
    return !!this.getToken() && !!this.getUserRole();
  }

  getToken(): string | null {
    return localStorage.getItem(this.tokenKey) || null;
  }

  setToken(token: string): void {
    localStorage.setItem(this.tokenKey, token);
  }

  clearToken(): void {
    localStorage.removeItem(this.tokenKey);
  }

  extractToken(response: any): string | null {
    return response.data.token;
  }

  getUserRole(): string | null {
    this.userRole = localStorage.getItem('role');

    return this.userRole; 
  }

  register(data: FranchisorData) {
    return this.http.post<any>(`${this.apiUrl}/auth/register`, { ...data })
      .pipe(
        tap((response: Response) => {
        })
      );
  }

  sendOtp(phone: string): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/auth/sendOtp`, {
      phoneNumber: phone,
    });
  }

  verifyOtp(phone: string, otp: string): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/auth/verifyOtp`, {
      phoneNumber: phone,
      otp,
    });
  }

  sendResetPasswordOtp(phone: string): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/auth/sendResetPasswordOtp`, {
      phoneNumber: phone,
    });
  }

  verifyResetPasswordOtp(phone: string, otp: string): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/auth/verifyResetPasswordOtp`, {
      phoneNumber: phone,
      otp,
    });
  }

  resetPassword(token: string, password: string): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/auth/resetPassword`, {
      token,
      password,
    });
  }
}
